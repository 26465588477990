import "@aws-amplify/ui-react/styles.css";
import { Authenticator } from "@aws-amplify/ui-react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Sidebar from "./components/Sidebar";
import CompaniesListScreen from "./pages/companies";
import CompaniesShowScreen from "./pages/companies/show";
import UsersList from "./pages/users";
import ContentScreen from "./pages/content";
import EventLogPage from "./pages/event-log";
import TradesScreen from "./pages/trades";
import TradeShowScreen from "./pages/trades/show";
import CompanyEventLogPage from "./pages/event-log/company";
import SettingsScreen from "./pages/settings";
import PlotListScreen from "./pages/plots";
import PlotShowScreen from "./pages/plots/show";
import UnregisteredUserScreen from "./pages/errors/user-not-registered";
import Error404Screen from "./pages/errors/404";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoomScreen from "./pages/content/room";
import SiteListScreen from "./pages/sites";
import SiteShowScreen from "./pages/sites/show";

function App() {
  return (
    <>
      <Authenticator
        hideSignUp
        className="flex items-center justify-center w-screen h-screen"
      >
        {({ user }) => {
          const isSuperAdmin = user
            ?.getSignInUserSession()
            ?.getIdToken()
            .payload["cognito:groups"].includes("SuperAdmins");
          return (
            <div className="grid grid-cols-12 bg-gray-100 min-h-screen ">
              <div className="col-span-2">
                <Sidebar />
              </div>
              <div className="col-span-10">
                <Routes>
                  <Route
                    path="/"
                    element={<Dashboard isSuperAdmin={isSuperAdmin} />}
                  />
                  {isSuperAdmin ? (
                    <>
                      <Route
                        path="/companies"
                        element={<CompaniesListScreen />}
                      />
                      <Route
                        path="/companies/:companyId"
                        element={<CompaniesShowScreen />}
                      />
                      <Route path="/users" element={<UsersList />} />

                      <Route path="/activity-log" element={<EventLogPage />} />
                      <Route path="/content" element={<ContentScreen />} />

                      <Route
                        path="/content/rooms/:roomId"
                        element={<RoomScreen />}
                      />
                    </>
                  ) : (
                    <>
                      <Route path="/trades" element={<TradesScreen />} />

                      <Route
                        path="/trades/:tradeId"
                        element={<TradeShowScreen />}
                      />
                      <Route path="/sites" element={<SiteListScreen />} />
                      <Route path="/sites/:siteId" element={<SiteShowScreen />} />
                      {/* <Route path="/sites/:siteId/plots" element={<PlotListScreen />} /> */}
                      <Route
                        path="/sites/:siteId/plots/:plotId"
                        element={<PlotShowScreen />}
                      />

                      <Route
                        path="/activity-log"
                        element={<CompanyEventLogPage />}
                      />

                      <Route path="/settings" element={<SettingsScreen />} />
                    </>
                  )}

                  <Route
                    path="user-not-registered"
                    element={<UnregisteredUserScreen />}
                  />
                  <Route path="*" element={<Error404Screen />} />
                </Routes>
              </div>
            </div>
          );
        }}
      </Authenticator>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </>
  );
}

export default App;
